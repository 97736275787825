import './App.css';
import Jobs from 'Components/Pages/Jobs';
import Job from 'Components/Pages/Job';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import GenericTemplate from './Templates/GenericTemplate';


const App = () => {
  return <>
    <BrowserRouter>
      <Routes>
        <Route index element={<GenericTemplate><Jobs/></GenericTemplate>}/>
        <Route path="job/:id" element={<GenericTemplate><Job /></GenericTemplate>}/>
      </Routes>
    </BrowserRouter>

    </>

}
export default App;
