import {FC} from 'react';
import Header from 'Components/Organisms/Header';
import Footer from 'Components/Organisms/Footer';
import {Box} from "@mui/material";
import Theme from '../Components/ThemeProvider';
import {BrowserRouter} from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

export interface GenericTemplateProps {
  children: React.ReactNode;
}

const GenericTemplate:FC<GenericTemplateProps> = ({children}) => {
  // const [dropMenuOpen, setDropMenurOpen] = useState(false);

  return (
    <>
      <ThemeProvider theme={Theme}>

      <Header />
      <Box sx={{m: 2}}>
        {children}
      </Box>
      {/*<Footer/>*/}
      </ThemeProvider>
    </>
  )
}
export default GenericTemplate;
