import React, {Dispatch, FC, SetStateAction} from 'react';
import {Button, FormControl, Paper, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';
import {Job} from '../../Types/Job';
import CellHead from '../Atom/CellHed';
import Cell from 'Components/Atom/Cell';
import {makeStyles} from '@mui/styles';
import Title from '../Atom/Title';
type TProps ={
  job:Job
  setIsList:Dispatch<SetStateAction<boolean>>
  setDatailID:Dispatch<SetStateAction<string>>
}
const useStyles = makeStyles({
  pepar:{
    cursor:'pointer',
    padding: '1em',
    margin: '1em 0'
  },
  button: {
    fontSize:'120%',
    padding:'0.5em',
    color:'white'
  },
})

const JobList:FC<TProps> = ({job,setIsList,setDatailID}) =>{
  const classes = useStyles();

  const handleDtail = () => {
    setIsList(prevState => !prevState)
    setDatailID(job.id)
  }
  return (
    <Paper className={classes.pepar} key={job.job_id} onClick={handleDtail}>
    <Title>{job.job_title}</Title>
    <Typography variant="h5" sx={{mb: '0.5em'}}>{job.job_description}</Typography>

    <Table>
      <TableBody>
        <TableRow>
          <CellHead><Typography>賃金</Typography></CellHead>
          <Cell><Typography>{job.job_salary_text}</Typography></Cell>
        </TableRow>
        <TableRow>
          <CellHead><Typography>職種</Typography></CellHead>
          <Cell><Typography>{job.job_title}</Typography></Cell>
        </TableRow>
        <TableRow>
          <CellHead><Typography>雇用形態</Typography></CellHead>
          <Cell><Typography>{job.job_employment}</Typography></Cell>
        </TableRow>
        <TableRow>
          <CellHead><Typography>就業場所</Typography></CellHead>
          <Cell><Typography>{job.workplace_address}</Typography></Cell>
        </TableRow>

      </TableBody>
    </Table>

    <Typography sx={{margin: '3em', textAlign: 'center'}}>
      <FormControl fullWidth>
        <Button className={classes.button}>詳しくみる</Button>
      </FormControl>
    </Typography>


  </Paper>
  )
}
export default JobList
