import { FC } from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Config from 'Types/Config';
type Props ={
  onOpen?:() => void
}
const Header:FC<Props> = ({onOpen}) =>{
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          {onOpen && (
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon sx={{color:'#fff'}} onClick={onOpen} />
          </IconButton>
            )}
          <Typography sx={{color:'#fff'}} variant="h6" color="inherit" component="div">
            {Config.applicationTitle}


          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default Header
