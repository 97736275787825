import React, {Dispatch, FC, SetStateAction} from 'react';
import {useNavigate, useParams} from 'react-router';
import useFacthJob from '../../Hooks/useFacthJob';
import {Button, Paper, Stack, Table, TableCell, TableRow, Typography} from '@mui/material';
import CellHead from '../Atom/CellHed';
import Title from '../Atom/Title';
import Loading from './Loading';
import {makeStyles, styled} from '@mui/styles';
import Cell from '../Atom/Cell';

type TProp ={
  id:string
  setIsList:Dispatch<SetStateAction<boolean>>

}
const STable = styled(Paper)(({theme}) => ({
  marginBottom:"4em",
  width:'100%',
}));
const useStyles = makeStyles({
  goBack:{
    background: 'rgba(150,150,150,0.5);',
    border: 0,
    padding: '1rem',
    textAlign: 'center',
    bottom:'30px',
    position:"sticky"
  }
})


const JobDetail:FC<TProp> = ({id,setIsList}) =>{
  const classes = useStyles();
  const {job,isLoading} = useFacthJob(id)
  const hardlineClose =() =>{
    setIsList(prevState => !prevState)
  };
  return <>
    {<Loading showBackdrop={isLoading}/>}
    {job &&
    <>

      <Paper sx={{padding: '1em', margin: '1em 0'}}>
        <STable>
          <TableRow>
            <CellHead>求人番号</CellHead>
            <Cell>{job.job_id}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>受付年月日</CellHead>
            <Cell>{job.public_date}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>紹介期限日</CellHead>
            <Cell>{job.public_limit}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>受理安定所</CellHead>
            <Cell>---------------</Cell>
          </TableRow>
          <TableRow>
            <CellHead>求人区分</CellHead>
            <Cell>{job.public_statuts}</Cell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>オンライン自主応募の受付</CellHead>*/}

          {/*</TableRow>*/}
          {/*<TableRow>*/}
          {/*  <CellHead>産業分類</CellHead>*/}

          {/*</TableRow>*/}
          <TableRow>
            <CellHead>トライアル雇用併用の希望</CellHead>
            <Cell>{job.is_trial}</Cell>
          </TableRow>
        </STable>
        <Title>求人事業所</Title>
        <STable>
          <TableRow>
            <CellHead>事業所番号</CellHead>
            <Cell>{job.business_no}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>事業所名</CellHead>
            <Cell>{job.office_name}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>所在地</CellHead>
            <Cell>{job.office_address}</Cell>
          </TableRow>
          {job.office_url && <TableRow>
            <CellHead>ホームページ</CellHead>
            <Cell><a href={job.office_url}>{job.office_url}</a> </Cell>
          </TableRow>}
        </STable>
        <Title>仕事内容</Title>
        <STable>
          <TableRow>
            <CellHead>職種</CellHead>
            <Cell>{job.job_title}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>仕事内容</CellHead>
            <Cell>{job.job_description}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>雇用形態</CellHead>
            <Cell>{job.job_employment}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>派遣・請負等</CellHead>
            <Cell>{job.is_ispatch_worker}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>雇用期間</CellHead>
            <Cell>{job.employment_period}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>就業場所</CellHead>
            <Cell>{job.workplace_address}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>マイカー通勤</CellHead>
            <Cell>{job.is_my_car}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>転勤の可能性</CellHead>
            <Cell>{job.is_transfer}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>年齢</CellHead>
            <Cell>{job.is_age_limit}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>学歴</CellHead>
            <Cell>{job.is_educational_background}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>必要な経験等</CellHead>
            <Cell>{job.is_experience}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>必要な免許・資格</CellHead>
            <Cell>{job.is_licence}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>試用期間</CellHead>
            <Cell>
              <Stack direction="row" spacing={2}>
                {job.is_trial_employment}
                {job.is_trial_employment && `試用期間:${job.trial_employment_span}`}
                {job.is_trial_employment && `条件:${job.trial_employment_sarly}`}
              </Stack>
            </Cell>
          </TableRow>
        </STable>
        <Title>賃金・手当</Title>
        <STable>
          <TableRow>
            <CellHead>ａ ＋ ｂ（固定残業代がある場合はａ ＋ ｂ ＋ ｃ）</CellHead>
            <Cell>{job.salary_label} {job.salary_base}※フルタイム求人の場合は月額（換算額）、パート求人の場合は時間額を表示しています。</Cell>
          </TableRow>
          <TableRow>
            <CellHead>基本給（ａ）</CellHead>
            <Cell>{job.salary_label} {job.salary_base}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>定額的に支払われる手当（ｂ）</CellHead>
            <Cell>
              {job.job_allowance_1 && `・${job.job_allowance_1}`}
              {job.job_allowance_2 && `・${job.job_allowance_2}`}
              {job.job_allowance_3 && `・${job.job_allowance_3}`}
              {job.job_allowance_4 && `・${job.job_allowance_4}`}

            </Cell>
          </TableRow>
          <TableRow>
            <CellHead>固定残業代（ｃ）</CellHead>
            <Cell>{job.is_fixed_overtime_salary}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>月平均労働日数</CellHead>
            <Cell>{job.amount_work_day}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>賃金形態等</CellHead>
            <Cell>{job.salary_type_code === '4' ? '時給' : '月給'}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>通勤手当</CellHead>
            <Cell>{job.job_commutation}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>賃金締切日</CellHead>
            <Cell>{job.salary_deadline}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>賃金支払日</CellHead>
            <Cell>{job.salary_pay}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>昇給</CellHead>
            <Cell>{job.is_increase_salary}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>賞与</CellHead>
            <Cell>{job.is_bonus}</Cell>
          </TableRow>
        </STable>
        <Title>労働時間</Title>
        <STable>
          <TableRow>
            <CellHead>就業時間</CellHead>
            <Cell>
              {job.working_hours_1 && `・${job.working_hours_1}`}
              {job.working_hours_2 && `・${job.working_hours_2}`}
              {job.working_hours_3 && `・${job.working_hours_3}`}

            </Cell>
          </TableRow>
          <TableRow>
            <CellHead>時間外労働時間</CellHead>
            <Cell>{job.is_overtime_work}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>休憩時間</CellHead>
            <Cell>{job.break_time}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>年間休日数</CellHead>
            <Cell>{job.holiday_year}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>休日等</CellHead>
            <Cell>
              {job.holiday_label}
            </Cell>
          </TableRow>
        </STable>
        <Title>その他の労働条件等</Title>

        <STable>
          <TableRow>
            <CellHead>加入保険等</CellHead>
            <Cell>{job.insurance}</Cell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>退職金共済</CellHead>*/}

          {/*</TableRow>*/}
          <TableRow>
            <CellHead>退職金制度</CellHead>
            <Cell>{job.retirement_plan}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>定年制</CellHead>
            <Cell>{job.is_age_limit_system}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>再雇用制度</CellHead>
            <Cell>{job.is_reemployment}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>勤務延長</CellHead>
            <Cell>{job.is_reemployment}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>入居可能住宅</CellHead>
            <Cell>
              {job.company_house_single}
              {job.company_house_family}
            </Cell>
          </TableRow>
          <TableRow>
            <CellHead>利用可能託児施設</CellHead>
            <Cell>{job.child_minding_facility}</Cell>
          </TableRow>
        </STable>
        <Title>会社の情報</Title>

        <STable>
          <TableRow>
            <CellHead>従業員数</CellHead>
            <Cell>全体　{job.all_employees}(うち、女性{job.workplace_employees_woman})(うち、パート{job.workplace_employees_part_timer})</Cell>
          </TableRow>
          <TableRow>
            <CellHead>設立年</CellHead>
            <Cell>{job.company_year_founded}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>資本金</CellHead>
            <Cell>{job.capital}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>労働組合</CellHead>
            <Cell>{job.company_union}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>事業内容</CellHead>
            <Cell>{job.business_content}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>会社の特長</CellHead>
            <Cell>{job.business_features}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>役職／代表者名</CellHead>
            <Cell>{job.company_postition} {job.company_ceo}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>法人番号</CellHead>
            <Cell>{job.business_no}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>就業規則</CellHead>
            <Cell>フルタイム {job.rules_full_time} <br/>パートタイム {job.rules_part_timer}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>育児休業取得実績</CellHead>
            <Cell>{job.maternity_leave}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>介護休業取得実績</CellHead>
            <Cell>{job.caregiving_leave}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>看護休暇取得実績</CellHead>
            <Cell>{job.nursing_leave}</Cell>
          </TableRow>
        </STable> <Title>選考等</Title>

        <STable>
          <TableRow>
            <CellHead>採用人数</CellHead>
            <Cell>{job.admitted}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>選考方法</CellHead>
            <Cell>{job.selection_process}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>選考結果通知</CellHead>
            <Cell>{job.selection_results}</Cell>
          </TableRow>
          <TableRow>
            <CellHead>求職者への通知方法</CellHead>
            <Cell>{job.selection_inform}</Cell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>選考日時等</CellHead>*/}

          {/*</TableRow>*/}
          {/*<TableRow>*/}
          {/*  <CellHead>選考場所</CellHead>*/}

          {/*</TableRow>*/}
          <TableRow>
            <CellHead>応募書類等</CellHead>
            <Cell>{job.selection_goods}</Cell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>応募書類の返戻</CellHead>*/}

          {/*</TableRow>*/}
          <TableRow>
            <CellHead>担当者</CellHead>
            <Cell>{job.office_staff_divstion} {job.office_staff_name}</Cell>
          </TableRow>

        </STable>


      </Paper>
      <Typography className={classes.goBack}><Button size="large" onClick={hardlineClose}>一覧に戻る</Button></Typography>
    </>
    }</>

}
export default JobDetail
