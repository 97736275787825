import {FC} from 'react';
import {Backdrop, Box, CircularProgress} from '@mui/material';
type Prop={
  showBackdrop:boolean
}
const Loading:FC<Prop> = ({showBackdrop}) =>{
  return (
    <Backdrop open={showBackdrop} style={{zIndex:1}}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loading
