import {useEffect, useState} from 'react';
import {Job} from '../Types/Job'
import axios from 'axios';
import {SearchParams} from '../Types/SearchParams';
import Config from '../Types/Config';

interface IResponse {
  jobs:IJobs
}
interface IJobs {
  data: Job[],
  next_page_url: string,
  total: number
  current_page: number
}
const useFacthJobs = ()=> {
  const [jobs,setJobs]= useState<Job[]>([])
  const [total,setTotal]= useState<number>(0)
  const [currentPage,setCurrentPage]= useState<number>(0)
  const [isLoading,setIsLoading]= useState<boolean>(false)
  const handleReset = (searchParams:SearchParams) =>{
    console.log('---------------------reset------------------------------')
    setTotal(0)
    const arr:Job[] = []
    setJobs(prevState => arr)
    facthRequest(searchParams,0)
  }

  const facthRequest = (
    searchParams:SearchParams = {pref_id:'',city_id:'',jobTypeA:'',jobTypeB:'',jobTypeC:'',keyword:''},
    page:number = 0
    ) =>{
    setIsLoading(prevState => true)
    if(total <= jobs.length && total > 0) {
      setIsLoading(prevState => false)
      return

    }

    // if(isReset) setCurrentPage(1)

    const parms = {...searchParams,page:page + 1}

    axios.get<IResponse>('/api/jobs',{baseURL:Config.apiUrl,params: parms})
      .then((res) =>{
        console.log('---------------------Facth------------------------------')
        console.log('jobs.length:' + jobs.length)
        console.log('total:' + total)
        if(page === 0){
          setJobs(res.data.jobs.data)
        }else {
          let newJobs = jobs.concat()
          res.data.jobs.data.map((j) =>{
            newJobs.push(j)
          })
          setJobs(newJobs)

        }
        setTotal(res.data.jobs.total)
        setCurrentPage(res.data.jobs.current_page)
        setIsLoading(prevState => false)
      }).catch((e)=>{
        console.log(e)
     })

  }
  useEffect(() =>{
    setIsLoading(prevState => true)
    facthRequest()
  },[])

  return {isLoading,setIsLoading,total,jobs,facthRequest,handleReset,currentPage,setCurrentPage}
}
export default useFacthJobs
