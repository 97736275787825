import React, {FC} from 'react';
import {Paper, Typography} from '@mui/material';
import {styled} from '@mui/styles';
const STypography = styled(Typography)(({theme}) => ({
  marginBottom:'0.5em',
  padding:0,
  borderBottom:'1px solid #ddd',
  width:'100%',
}));

const Title:FC = ({children}) =>{
  return <>  <STypography variant="h3" >{children}</STypography></>
}
export default Title
