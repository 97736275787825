import {useEffect, useState} from 'react';
import axios from 'axios';
import Config from '../Types/Config';
interface IPref {
  id:number
  pref_name:string
}
interface ICity {
  id:number
  pref_id:number
  city_name:string
}
interface IJobTypeA {
  id:number
  id_char:string
  name:string
}
interface IJobTypeB {
  id:number
  type_a_char:string
  name:string
}
interface IJobTypeC {
  id:number
  type_b_id:string
  type_char:string
  name:string
}
interface IResponse {
  prefs:IPref[]
  cities:ICity[]
  jobTypeA:IJobTypeA[]
  jobTypeB:IJobTypeB[]
  jobTypeC:IJobTypeC[]
}
const useFacthParams = () => {
  const [prefs,setPrefs]= useState<IPref[]>([])
  const [cities,setCities]= useState<ICity[]>([])
  const [jobTypeA,setJobTypeA]= useState<IJobTypeA[]>([])
  const [jobTypeB,setJobTypeB]= useState<IJobTypeB[]>([])
  const [jobTypeC,setJobTypeC]= useState<IJobTypeC[]>([])
  const facthRequest = () =>{
     axios.post<{params:IResponse}>('/api/jobs/params',{},{baseURL:Config.apiUrl})
      .then((res) =>{
        setCities(res.data.params.cities)
        setPrefs(res.data.params.prefs)
        console.log(res.data.params)
        console.log(res.data.params.jobTypeA)
        setJobTypeA(res.data.params.jobTypeA)
        setJobTypeB(res.data.params.jobTypeB)
        setJobTypeC(res.data.params.jobTypeC)
        console.log(jobTypeA)
      }).catch((e)=>{
        console.log('params fatch err' + e)
     })

  }
  useEffect(() =>{ facthRequest()},[])

  return {prefs,cities,setCities,jobTypeA,jobTypeB,jobTypeC,setJobTypeB,setJobTypeC}
}
export default useFacthParams
