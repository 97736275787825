import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import useFacthParams from '../../Hooks/useFacthParams';
import {SearchParams} from '../../Types/SearchParams';
import {makeStyles} from '@mui/styles';

type Props = {
  searchParams: SearchParams
  setSearchParams: Dispatch<SetStateAction<SearchParams>>
  onClick: VoidFunction

}

const useStyles = makeStyles({
  button: {
    fontSize:'120%',
    padding:'0.5em',
    color:'white'
  },
});

const SearchBox: FC<Props> = ({searchParams, setSearchParams, onClick}) => {
  const classes = useStyles();

  const {prefs, cities,jobTypeA,jobTypeB,jobTypeC} = useFacthParams()

  const handleChangePref = (id: string) => {
    setSearchParams(preState => ({...preState, pref_id: id}))
  }
  const handleChangeCity = (id: string) => {
    setSearchParams(preState => ({...preState, city_id: id}))
  }
  const handleChangeJobTypeA = (id_char: string) => {
    setSearchParams(preState => ({...preState, jobTypeA: id_char}))
  }
  const handleChangeJobTypeB = (id: string) => {
    setSearchParams(preState => ({...preState, jobTypeB: id}))
  }
  const handleChangeJobTypeC = (id_char: string) => {
    setSearchParams(preState => ({...preState, jobTypeC: id_char}))
  }
  const handleChangeKeyword = (keyword: string) => {
    setSearchParams(preState => ({...preState, keyword: keyword}))
  }
  const handleReset = (params:SearchParams) => {
    setSearchParams(preState => params)
    onClick()
  }

  return <>
    <Paper sx={{padding: '1em', margin: '1em 0'}}>
      <Typography variant="h4" sx={{my:'0.5rem'}}>就業エリア</Typography>
      <Grid container spacing={2} >
      <Grid item xs={5}>
        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">都道府県</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchParams.pref_id}
            label="都道府県"
            onChange={(e) => {
              handleChangePref(e.target.value)
            }}
          >
            {prefs.map((value,index) => (
              <MenuItem  key={`pref${index}`} value={value.id}>{value.pref_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth>

          <InputLabel id="city-select-label">市町村</InputLabel>
          <Select
            labelId="city-select-label"
            id="city-select"
            value={searchParams.city_id}
            label="市町村"
            onChange={(e) => {
              handleChangeCity(e.target.value)
            }}

          >
            {cities.filter((value) => value.pref_id.toString() == searchParams.pref_id).map((value,index) => (
              <MenuItem key={`city${index}`} value={value.id}>{value.city_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      </Grid>
      <Typography variant="h4" sx={{mt:'1.5rem'}}>職種</Typography>
      <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormControl fullWidth>

          <InputLabel id="jobTypeA">大分類</InputLabel>
          <Select
            labelId="jobTypeA"
            id="demo-simple-select"
            value={searchParams.jobTypeA}
            label="大分類"
            onChange={(e) => {
              handleChangeJobTypeA(e.target.value)
            }}
          >
            {jobTypeA.map((value,index) => (
              <MenuItem key={`jobTypeA${index}`} value={value.id_char}>{value.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

      </Grid>

      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel sx={{opacity:searchParams.jobTypeA.length == 0 ? '10%' : '100%'}} id="jobTyoeB-select-label">中分類</InputLabel>
          <Select
            labelId="jobTyoeB-select-label"
            id="jobTyoeB-select"
            sx={{opacity:searchParams.jobTypeA.length == 0 ? '10%' : '100%'}}
            value={searchParams.jobTypeB}
            label="中分類"
            onChange={(e) => {
              handleChangeJobTypeB(e.target.value)
            }}

          >
            {jobTypeB.filter((value) => value.type_a_char.toString() == searchParams.jobTypeA).map((value,index) => (
              <MenuItem key={`jobTypeB${index}`} value={value.id}>{value.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl fullWidth>

          <InputLabel sx={{opacity:searchParams.jobTypeB.length == 0 ? '10%' : '100%'}} id="jobTyoeC-select-label">小分類</InputLabel>
          <Select
            labelId="jobTyoeC-select-label"
            sx={{opacity:searchParams.jobTypeB.length == 0 ? '10%' : '100%'}}
            id="jobTyoeC-select"
            value={searchParams.jobTypeC}
            label="小分類"
            onChange={(e) => {
              handleChangeJobTypeC(e.target.value)
            }}
          >
            {jobTypeC.filter((value) => value.type_b_id == searchParams.jobTypeB).map((value,index) => (
              <MenuItem key={`jobTypeB${index}`} value={value.type_char}>{value.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      </Grid>
      <Typography variant="h4" sx={{mt:'1.5rem'}}>キーワード</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField id="keyword" label="キーワー ド" variant="outlined" value={searchParams.keyword} onChange={event => handleChangeKeyword(event.target.value)} />
          </FormControl>

        </Grid>
      </Grid>
        <Typography sx={{margin: '3em', textAlign: 'center'}}>
          <FormControl fullWidth>
            <Button className={classes.button} onClick={event => onClick()}>検索する</Button>
          </FormControl>
        </Typography>
      {/*<Typography sx={{margin: '3em', textAlign: 'center'}}>*/}
      {/*    <FormControl fullWidth>*/}
      {/*      <Button className={classes.button} onClick={event => handleReset({pref_id:'',city_id:'',jobTypeA:'',jobTypeB:'',jobTypeC:'',keyword:''})}>リセットする</Button>*/}
      {/*    </FormControl>*/}
      {/*  </Typography>*/}

    </Paper>
  </>
}
export default SearchBox
