import { createTheme } from '@mui/material/styles';

const Theme =createTheme({
  palette: {
    primary: {
      main: '#93c746',
    },
    secondary: {
      main: '#d4b17d',
    },
    error: {
      main: '#c51162',
    },
    info: {
      main: '#3949ab',
    },
    success: {
      main: '#0d47a1',
    },
    text: {
      primary: 'rgba(80,79,79,0.87)',
    },
  },
  typography: {
  // In Chinese and Japanese the characters are usually larger,
  // so a smaller fontsize may be appropriate.
    fontSize: 12,
  },
  components:{
    MuiButton:{
      defaultProps:{
        variant:'contained'
      }
    }
  }
})
Theme.typography.h1 = {
  fontSize:'1.5rm'
}
export default Theme
