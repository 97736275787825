import {FC} from 'react';
import {TableCell} from '@mui/material';
import {makeStyles} from '@mui/styles';
const useStyles = makeStyles({
  head:{
    backgroundColor:'#eee',
    width:'10em',
    fontSize:'120%'
  }
})


const CellHead:FC = ({children}) =>{
  const classes = useStyles();
  return <><TableCell className={classes.head}>{children}</TableCell></>
}
export default CellHead
