type _Config ={
  apiUrl:string
  applicationTitle:string
  weeks:Array<string>
  temsErrorPostURL:string
}

const Config:_Config={
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost",
  applicationTitle:process.env.REACT_APP_APPLICATION_NAME ||"ハローワーク　仕事情報検索システム",
  weeks:"日,月,火,水,木,金,土".split(','),
  temsErrorPostURL:process.env.REACT_APP_TEAMS_ERROR_POST_URL || 'https://passo01.webhook.office.com/webhookb2/778ba993-2c5d-4688-aa73-11e216d47eef@077d149b-1c46-4215-b1e5-c602c687cbfb/IncomingWebhook/47ffc5a8f2d245bca18b7bb01810cdf4/22a9510b-5acd-471c-95a9-844f628c7fd2'
}
export default Config
