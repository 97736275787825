import {useNavigate, useParams} from 'react-router';
import useFacthJob from '../../Hooks/useFacthJob';
import {Button, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';
import React from 'react';
import Title from 'Components/Atom/Title';
import CellHead from '../Atom/CellHed';
import {styled} from '@mui/styles';

const Item = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
}));

type TJobParms = {
  id: string
}

const Job = () => {
  const {id} = useParams<TJobParms>()
  const {job} = useFacthJob(id)
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate('/')
  }

  return <>job{id}
    {job &&
    <>

      <Paper sx={{padding: '1em', margin: '1em 0'}}>
        <Table>
          <TableRow>
            <CellHead>求人番号</CellHead>
            <TableCell>{job.job_id}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>受付年月日</CellHead>
            <TableCell>{job.public_date}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>紹介期限日</CellHead>
            <TableCell>{job.public_limit}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>受理安定所</CellHead>
            <TableCell>---------------</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>求人区分</CellHead>
            <TableCell>{job.public_statuts}</TableCell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>オンライン自主応募の受付</CellHead>*/}
          {/*  <TableCell>---------------</TableCell>*/}
          {/*</TableRow>*/}
          {/*<TableRow>*/}
          {/*  <CellHead>産業分類</CellHead>*/}
          {/*  <TableCell>---------------</TableCell>*/}
          {/*</TableRow>*/}
          <TableRow>
            <CellHead>トライアル雇用併用の希望</CellHead>
            <TableCell>{job.is_trial}</TableCell>
          </TableRow>
        </Table>
        <Title>求人事業所</Title>
        <Table>
          <TableRow>
            <CellHead>事業所番号</CellHead>
            <TableCell>{job.business_no}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>事業所名</CellHead>
            <TableCell>{job.office_name}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>所在地</CellHead>
            <TableCell>{job.office_address}</TableCell>
          </TableRow>
          {job.office_url && <TableRow>
            <CellHead>ホームページ</CellHead>
            <TableCell><a href={job.office_url}>{job.office_url}</a> </TableCell>
          </TableRow>}
        </Table>
        <Title>仕事内容</Title>
        <Table>
          <TableRow>
            <CellHead>職種</CellHead>
            <TableCell>{job.job_title}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>仕事内容</CellHead>
            <TableCell>{job.job_description}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>雇用形態</CellHead>
            <TableCell>{job.job_employment}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>派遣・請負等</CellHead>
            <TableCell>{job.is_ispatch_worker}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>雇用期間</CellHead>
            <TableCell>{job.employment_period}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>就業場所</CellHead>
            <TableCell>{job.workplace_address}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>マイカー通勤</CellHead>
            <TableCell>{job.is_my_car}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>転勤の可能性</CellHead>
            <TableCell>{job.is_transfer}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>年齢</CellHead>
            <TableCell>{job.is_age_limit}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>学歴</CellHead>
            <TableCell>{job.is_educational_background}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>必要な経験等</CellHead>
            <TableCell>{job.is_experience}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>必要な免許・資格</CellHead>
            <TableCell>{job.is_licence}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>試用期間</CellHead>
            <TableCell>
              <Stack direction="row" spacing={2}>
                <Item>{job.is_trial_employment}</Item>
                <Item>{job.is_trial_employment && `試用期間:${job.trial_employment_span}`}</Item>
                <Item>{job.is_trial_employment && `条件:${job.trial_employment_sarly}`}</Item>
              </Stack>
                </TableCell>
          </TableRow>
        </Table>
        <Title>賃金・手当</Title>
        <Table>
          <TableRow>
            <CellHead>ａ ＋ ｂ（固定残業代がある場合はａ ＋ ｂ ＋ ｃ）</CellHead>
            <TableCell>{job.salary_label} {job.salary_base}※フルタイム求人の場合は月額（換算額）、パート求人の場合は時間額を表示しています。</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>基本給（ａ）</CellHead>
            <TableCell>{job.salary_label} {job.salary_base}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>定額的に支払われる手当（ｂ）</CellHead>
            <TableCell>
              {job.job_allowance_1 && `・${job.job_allowance_1}`}
              {job.job_allowance_2 && `・${job.job_allowance_2}`}
              {job.job_allowance_3 && `・${job.job_allowance_3}`}
              {job.job_allowance_4 && `・${job.job_allowance_4}`}

            </TableCell>
          </TableRow>
          <TableRow>
            <CellHead>固定残業代（ｃ）</CellHead>
            <TableCell>{job.is_fixed_overtime_salary}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>月平均労働日数</CellHead>
            <TableCell>{job.amount_work_day}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>賃金形態等</CellHead>
            <TableCell>{job.salary_type_code === '4' ? '時給' : '月給'}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>通勤手当</CellHead>
            <TableCell>{job.job_commutation}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>賃金締切日</CellHead>
            <TableCell>{job.salary_deadline}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>賃金支払日</CellHead>
            <TableCell>{job.salary_pay}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>昇給</CellHead>
            <TableCell>{job.is_increase_salary}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>賞与</CellHead>
            <TableCell>{job.is_bonus}</TableCell>
          </TableRow>
        </Table>
        <Title>労働時間</Title>
        <Table>
          <TableRow>
            <CellHead>就業時間</CellHead>
            <TableCell>
              {job.working_hours_1 && `・${job.working_hours_1}`}
              {job.working_hours_2 && `・${job.working_hours_2}`}
              {job.working_hours_3 && `・${job.working_hours_3}`}

            </TableCell>
          </TableRow>
          <TableRow>
            <CellHead>時間外労働時間</CellHead>
            <TableCell>{job.is_overtime_work}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>休憩時間</CellHead>
            <TableCell>{job.break_time}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>年間休日数</CellHead>
            <TableCell>{job.holiday_year}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>休日等</CellHead>
            <TableCell>
              {job.holiday_label}
            </TableCell>
          </TableRow>
        </Table>
        <Title>その他の労働条件等</Title>

        <Table>
          <TableRow>
            <CellHead>加入保険等</CellHead>
            <TableCell>{job.insurance}</TableCell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>退職金共済</CellHead>*/}
          {/*  <TableCell>---------------</TableCell>*/}
          {/*</TableRow>*/}
          <TableRow>
            <CellHead>退職金制度</CellHead>
            <TableCell>{job.retirement_plan}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>定年制</CellHead>
            <TableCell>{job.is_age_limit_system}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>再雇用制度</CellHead>
            <TableCell>{job.is_reemployment}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>勤務延長</CellHead>
            <TableCell>{job.is_reemployment}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>入居可能住宅</CellHead>
            <TableCell>
              {job.company_house_single}
              {job.company_house_family}
            </TableCell>
          </TableRow>
          <TableRow>
            <CellHead>利用可能託児施設</CellHead>
            <TableCell>{job.child_minding_facility}</TableCell>
          </TableRow>
        </Table>
        <Title>会社の情報</Title>

        <Table>
          <TableRow>
            <CellHead>従業員数</CellHead>
            <TableCell>全体　{job.all_employees}(うち、女性{job.workplace_employees_woman})(うち、パート{job.workplace_employees_part_timer})</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>設立年</CellHead>
            <TableCell>{job.company_year_founded}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>資本金</CellHead>
            <TableCell>{job.capital}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>労働組合</CellHead>
            <TableCell>{job.company_union}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>事業内容</CellHead>
            <TableCell>{job.business_content}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>会社の特長</CellHead>
            <TableCell>{job.business_features}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>役職／代表者名</CellHead>
            <TableCell>{job.company_postition} {job.company_ceo}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>法人番号</CellHead>
            <TableCell>{job.business_no}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>就業規則</CellHead>
            <TableCell>フルタイム {job.rules_full_time} <br/>パートタイム {job.rules_part_timer}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>育児休業取得実績</CellHead>
            <TableCell>{job.maternity_leave}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>介護休業取得実績</CellHead>
            <TableCell>{job.caregiving_leave}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>看護休暇取得実績</CellHead>
            <TableCell>{job.nursing_leave}</TableCell>
          </TableRow>
        </Table> <Title>選考等</Title>

        <Table>
          <TableRow>
            <CellHead>採用人数</CellHead>
            <TableCell>{job.admitted}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>選考方法</CellHead>
            <TableCell>{job.selection_process}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>選考結果通知</CellHead>
            <TableCell>{job.selection_results}</TableCell>
          </TableRow>
          <TableRow>
            <CellHead>求職者への通知方法</CellHead>
            <TableCell>{job.selection_inform}</TableCell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>選考日時等</CellHead>*/}
          {/*  <TableCell>---------------</TableCell>*/}
          {/*</TableRow>*/}
          {/*<TableRow>*/}
          {/*  <CellHead>選考場所</CellHead>*/}
          {/*  <TableCell>---------------</TableCell>*/}
          {/*</TableRow>*/}
          <TableRow>
            <CellHead>応募書類等</CellHead>
            <TableCell>{job.selection_goods}</TableCell>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <CellHead>応募書類の返戻</CellHead>*/}
          {/*  <TableCell>---------------</TableCell>*/}
          {/*</TableRow>*/}
          <TableRow>
            <CellHead>担当者</CellHead>
            <TableCell>{job.office_staff_divstion} {job.office_staff_name}</TableCell>
          </TableRow>
          {/*<TableCell>---------------</TableCell>*/}
        </Table>


      </Paper>
      <Typography sx={{margin: '3em', textAlign: 'center'}}><Button onClick={handleGoBack}>一覧に戻る</Button></Typography>
    </>
    }</>

}

export default Job
