import useFacthJobs from '../../Hooks/useFacthJobs';
import React, {useEffect, useRef, useState} from 'react';
import {Alert, Grid, Typography} from '@mui/material';
import {useIntersection} from '../../Hooks/useIntersection';
import SearchBox from '../Organisms/SearchBox';
import {SearchParams} from '../../Types/SearchParams';
import Loading from '../Organisms/Loading';
import JobList from '../Organisms/JobList';
import JobDetail from '../Organisms/JobDetail';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  info: {
    background: 'yellowgreen',
    border: 0,
    borderRadius: 3,
    color: 'white',
    padding: '1rem',
    textAlign: 'center',
    top:'0px',
    position:"sticky"
  },
});

const Jobs = () => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>
  const {jobs, total, isLoading, setIsLoading, handleReset, facthRequest, currentPage} = useFacthJobs()

  const intersection = useIntersection(ref)


  const [isList, setIsList] = useState<boolean>(true)
  const [datailID, setDatailID] = useState<string>('')
  const [searchParams, setSearchParams] = useState<SearchParams>({
    pref_id: '',
    city_id: '',
    jobTypeA: '',
    jobTypeB: '',
    jobTypeC: '',
    keyword: ''
    })

  const handleSearch = () => {
    handleReset(searchParams)
  }
  useEffect(() => {
    console.log('スクロール')

    console.log('intersection:' + intersection)
    console.log('isLoading:' + isLoading)
    if (intersection && isList && !isLoading && jobs.length > 0) {
      setIsLoading(prevState => true)
      facthRequest(searchParams, currentPage)
    }
  }, [intersection])

  // if(jobs.length === 0) return <></>
  return <>
    <Grid sx={{position:'sticky','top':20,display:'flex'}}>
    {/*intersection:{intersection ? 1 : 0}<br/>*/}
    {/*isList:{isList ? 1 : 0}<br/>*/}
    {/*isLoading:{isLoading ? 1 : 0}<br/>*/}
    {/*total{total}<br/>*/}
    {/*jobs.length:{jobs.length}<br/>*/}
    </Grid>
    {isList && <>
     <SearchBox searchParams={searchParams} setSearchParams={setSearchParams} onClick={handleSearch}/>
    {jobs.length === 0 && !isLoading &&
      <Alert severity="error">仕事情報は、見つかりませんでした。</Alert>
    }


    {jobs.length > 0 && <>
      <Typography className={classes.info} >{jobs.length}/{total}件を表示しています</Typography>
      {jobs.map((job) => (
          <JobList setIsList={setIsList} setDatailID={setDatailID} job={job}/>
        )
      )}
      </>}
      </>}
    {!isList && <JobDetail id={datailID} setIsList={setIsList}/>}
    {/*intersection:{intersection ? 1 : 0}>*/}<br/>
    {/*isLoading:{isLoading ? 1 : 0}<br/>*/}
    <div ref={ref}>{intersection && <>{<Loading showBackdrop={isLoading}/>}</>}</div>
  </>
}

export default Jobs
