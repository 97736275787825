import {useEffect, useState} from 'react';
import {Job} from '../Types/Job';
import axios from 'axios';
import Config from '../Types/Config';

const useFacthJob = (id:string|undefined) => {
  const [job,setJob]= useState<Job|null>(null)
  const [isLoading,setIsLoading]= useState<boolean>(false)

  const facthRequest = () =>{
      setIsLoading(prevState => true)
     axios.post<{job:Job}>('/api/jobs/store',{id:id},{baseURL:Config.apiUrl})
      .then((res) =>{

        setJob(res.data.job)
        setIsLoading(prevState => false)
      }).catch((e)=>{
        console.log(e)
     })

  }
  useEffect(() =>{ facthRequest()},[])

  return {job: job,isLoading}
}
export default useFacthJob
